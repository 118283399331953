import React from 'react';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';

import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import { Avatar, ReviewRating, UserDisplayName } from '../../components';

import css from './Reviews.module.css';
import moment from 'moment';

const Review = props => {
  const { review, intl, customReview } = props;

  const date = review.attributes.createdAt;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });

  return (
    <div className={css.review}>
      <Avatar className={css.avatar} user={review.author} />
      <div className={css.customerReview}>
        <ReviewRating
          rating={review.attributes.rating}
          className={css.mobileReviewRating}
          reviewStarClassName={css.reviewRatingStar}
        />        
        <p className={css.reviewInfo}>
          <UserDisplayName user={review.author} intl={intl} />
          <span className={css.separator}>•</span>
          {dateString}
          <span className={css.desktopSeparator}>•</span>
          <span className={css.desktopReviewRatingWrapper}>
            <ReviewRating
              rating={review.attributes.rating}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
          </span>
        </p>
        <p className={css.reviewContent}>{review.attributes.content}</p>
      </div>
    </div>
  );
};

const CustomReview = props => {
  const { customReview } = props;
  function getAbbreviation(str) {
    const words = str?.trim()?.split(/\s+/) || []; // Split by spaces and handle multiple spaces
    if (words.length >= 2) {
      return (words[0][0] + words[1][0]).toUpperCase(); // Get first letters of first two words
    } else if (words.length === 1) {
      return words[0].slice(0, 2).toUpperCase(); // If only one word, get first two letters
    }
    return ''; // If the string is empty or invalid
  }
  return (
    <div className={css.review}>
      <div className={css.avatarWrapper}>{getAbbreviation(customReview?.displayName)}</div>
      <div className={css.customerReview}>
        <ReviewRating
          rating={+customReview?.rating}
          className={css.mobileReviewRating}
          reviewStarClassName={css.reviewRatingStar}
        />       
        <p className={css.reviewInfo}>
          <span>{customReview?.displayName || "Anonymous User"}</span>
          <span className={css.separator}>•</span>
          <span>{customReview?.createdAt ? moment(customReview.createdAt).format('DD MMMM YYYY') : ''}</span>
          <span className={css.separator}>•</span>
          <span className={css.desktopReviewRatingWrapper}>
            <ReviewRating
              rating={+customReview?.rating}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
          </span>
        </p>
        <p className={css.reviewContent}>{customReview?.reviewContent}</p>
      </div>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const { className, rootClassName, reviews, intl, customReview } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <ul className={classes}>
      {reviews?.length > 0 ? (
        reviews.map(r => {
          return (
            <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
              <Review review={r} intl={intl} />
            </li>
          );
        })
      ) : (
        <CustomReview customReview={customReview} />
      )}
    </ul>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
